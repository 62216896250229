export enum Feature {
  AnalyzeDashboard = 'analyze_dashboard',
  ImmediateRewardDelivery = 'new_rewards_store_immediate_fulfillment',
  IncludeNTIDInReports = 'include_ntid_in_reports',
  IntegrationConnectionStatus = 'integration_connection_status',
  LocationInformation = 'location_information',
  MultipleJackpotWinners = 'multiple_jackpot_winners',
  PlayerVsPlayerByLocation = 'player_vs_player_by_location',
  NewRewardsPage = 'new_rewards_page',
  PerformanceDashboard = 'performance_dashboard',
  PlayerIdentifiers = 'player_identifiers',
  TremendousLinkDelivery = 'tremendous_link_delivery',
  ActivityDetails = 'activity_details',
  ButtonGuideCard = 'button_guide_card',
  PerformanceTeamFilter = 'performance_team_filter',
  ManageActivitiesTeamSales = 'manage_activities_team_sales',
  GuideStats = 'guide_stats',
}
